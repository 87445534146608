import React from "react"
import { Link } from "gatsby"
import classes from "./navbar.module.css"
import icon from "./icon.svg"

const navbar = props => {
  let toggleButton = props.sideDrawer
    ? [classes.open, classes.burger]
    : [classes.burger]

  return (
    <nav className={classes.navbar}>
      <Link to="/">
        <img className={classes.icon} src={icon} />
      </Link>
      <div onClick={props.clicked} className={toggleButton.join(" ")}>
        <div className={classes.bar1}></div>
        <div className={classes.bar2}></div>
        <div className={classes.bar3}></div>
      </div>
    </nav>
  )
}

export default navbar
