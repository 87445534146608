import React from "react"
import classes from "./Skills.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHtml5,
  faCss3Alt,
  faJs,
  faSwift,
  faReact,
  faNode,
} from "@fortawesome/free-brands-svg-icons"
import tsLogo from "../../images/ts-logo.png"

const Skills = () => {
  return (
    <section className={`${classes.wrapper} `}>
      <div className={classes.left}>Skills</div>
      <div className={classes.right}>
        <div className={classes.right__title}>Skills</div>
        <div className={classes.skills}>
          <FontAwesomeIcon className={classes.icon} icon={faHtml5} />
          <FontAwesomeIcon className={classes.icon} icon={faCss3Alt} />
          <FontAwesomeIcon className={classes.icon} icon={faJs} />
          <div className={classes.ts_logo}>
            <img src={tsLogo} alt="" />
          </div>
          <FontAwesomeIcon className={classes.icon} icon={faReact} />
          <FontAwesomeIcon className={classes.icon} icon={faNode} />
          <FontAwesomeIcon className={classes.icon} icon={faSwift} />
        </div>
        <div className={classes.interests}>
          <div className={classes.frontend_skills}>
            <div className={classes.skill}>HTML5</div>
            <div className={classes.skill}>CSS3</div>
            <div className={classes.skill}>JavaScript</div>
            <div className={classes.skill}>TypeScript</div>
            <div className={classes.skill}>React</div>
            <div className={classes.skill}>Webpack / Bable</div>
          </div>
          <div className={classes.libraries}>
            <div className={classes.skill}>Redux</div>
            <div className={classes.skill}>Node.js</div>
            <div className={classes.skill}>Express.js</div>
            <div className={classes.skill}>MongoDB</div>
            <div className={classes.skill}>Docker</div>
            <div className={classes.skill}>Swift / SwiftUI</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Skills
