import React, { useRef } from "react"
import classes from "./Works.module.css"
import poty from "../../images/poty.jpg"
import { text } from "@fortawesome/fontawesome-svg-core"
import potyLogo from "../../images/poty_logo.png"
import potyLogoWhite from "../../images/poty_logo_white.png"
import potyScreenShot from "../../images/poty_screen.png"
import appleBadge from "../../images/apple_badge.svg"
import potyDemo from "../../images/ezgif.com-gif-maker.gif"
import diaryDemo from "../../images/diary_demo.gif"

const Works = () => {
  return (
    <>
      <section className={classes.wrapper}>
        <div className={classes.left}>
          <div className={classes.modal}>
            <div className={classes.poty_logo}>
              <img src={potyLogo} />
            </div>
            <p className={classes.title}>Skate Spot Finder</p>
            <p className={classes.poty_description}>
              Drag around the map and push "Search this area" button. Fetching
              skate spots 50km radius circle on the map using geohash.
            </p>
            <p className={classes.skills}>
              TypeScript/React/React Hooks/Redux
              <br />
              Redux Thunk/Firebase
            </p>
            <span className={classes.link}>
              deployed{" "}
              <a
                href="https://tokyo-skate-spots.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              {" / "}
              <a
                href="https://github.com/yusukeyoshino/tokyostreetspots"
                target="_blank"
                rel="noopener noreferrer"
              >
                code
              </a>
            </span>
          </div>
        </div>

        <div className={classes.right_poty}>
          <img src={potyDemo} alt="" />
        </div>
      </section>
    </>
  )
}

export default Works
