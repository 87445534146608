import React, { useState } from "react"
import Navbar from "../navbar/navbar"
import styled from "styled-components"
import SideDrawer from "../../components/SideDrawer/SideDrawer"
import Footer from "../Footer/Footer"
import Skills from "../Skills/Skills"
import Works from "../Works/Works"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import "../../styles/reset.css"
import "./Layout.css"

const Layout = ({ children }) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false)

  const toggleDrawer = () => {
    const isOpened = isDrawerOpened
    setIsDrawerOpened(!isOpened)
  }

  const SectionWipes2Styled = styled.div`
    overflow: hidden;
    #pinContainer {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
    #pinContainer .panel {
      height: 100vh;
      width: 100vw;
      position: absolute;
      text-align: center;
    }
    .panel span {
      position: relative;
      display: block;
      top: 50%;
      font-size: 80px;
    }

    .panel.blue {
      background-color: #3883d8;
    }

    .panel.turqoise {
      background-color: #38ced7;
    }

    .panel.green {
      background-color: #22d659;
    }

    .panel.bordeaux {
      background-color: #953543;
    }
  `

  return (
    <>
      <SectionWipes2Styled>
        <Scene triggerHook="onLeave" duration="300%" pin>
          <Timeline wrapper={<div id="pinContainer" />}>
            <Tween from={{ x: "-100%" }} to={{ x: "0%" }}>
              <Skills />
            </Tween>
            <Works />
            <Footer />
          </Timeline>
        </Scene>
      </SectionWipes2Styled>
    </>
  )
}

export default Layout
