import React from "react"
import classes from "./Footer.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons"
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.icons}>
        <a
          href="https://www.linkedin.com/in/yusuke-yoshino-7707641a8/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon} icon={faLinkedin} />
        </a>
        <a
          href="https://github.com/yusukeyoshino"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon} icon={faGithub} />
        </a>
        <a
          href="mailto:y.yoshino1011@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon} icon={faEnvelope} />
        </a>
      </div>

      {/* <address className={classes.address}>
        <span className={classes.contact}>CONTACT ME{"　"}</span>
        <span className={classes.emoji}>✉️</span>
        <span className={classes.blinking}>
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
        {"　"}
        <span>y.yoshino1011@gmail.com</span>
      </address> */}
      {/* <small>© 2020 Yusuke Yoshino</small> */}
    </div>
  )
}

export default footer
